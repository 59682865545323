import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  QueryList,
  Self,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import {
  debounceTime,
  EMPTY,
  exhaustMap,
  filter,
  finalize,
  map,
  Observable,
  ReplaySubject,
  startWith,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';

import { TsDataScrollComponent } from '../data-scroll';
import { ListQueryFn } from '../base-list';
import {
  TsScrollComponent,
  TsDropdownListDirective,
  TsSelectOptionDirective,
} from '@topseller/ui';

interface TsEntity {
  id: string;
  name: string;
}

const PAGE_SIZE = 20;

@Component({
  selector: 'ts-entity-list',
  templateUrl: './entity-list.component.html',
  styleUrls: ['./entity-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TsEntityListComponent implements OnInit, AfterViewInit, OnDestroy {
  private scroll$: ReplaySubject<void> = new ReplaySubject<void>(1);
  private searchInternal: Subject<string | null> = new Subject<string | null>();

  @Input() queryFn?: ListQueryFn<TsEntity>;
  @Input() emptyMessage = 'НИЧЕГО НЕ НАЙДЕНО';
  @Input() itemTemplate?: TemplateRef<unknown>;
  @Input() hasSearch = true;
  @Input() multi = false;

  @Input()
  public set term(value: string | null) {
    if (value === null) {
      return;
    }
    this.searchInternal.next(value);
  }

  @Output() add = new EventEmitter<Event>();

  @ViewChild(TsScrollComponent, { read: ElementRef, static: true })
  private scrollContainer?: ElementRef<HTMLElement>;

  @ViewChild(TsDataScrollComponent, { read: ElementRef, static: true })
  dataScrollComponent?: ElementRef<HTMLElement>;

  @ViewChildren(TsSelectOptionDirective)
  public options?: QueryList<TsSelectOptionDirective>;

  public get dataScrollOptions(): IntersectionObserverInit {
    return { root: this.scrollContainer?.nativeElement };
  }

  public canAdd = false;
  public items$?: Observable<TsEntity[]>;
  public isLoading = false;
  public isLoaded = false;
  public searchControl = new FormControl('');

  private destroy$: Subject<void> = new Subject<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    @Optional() @Self() private dropdownList: TsDropdownListDirective
  ) {}

  public ngOnInit(): void {
    const search$ = this.hasSearch
      ? this.searchControl.valueChanges.pipe(
          filter((term) => !term || term.length >= 3),
          startWith('')
        )
      : this.searchInternal;

    this.items$ = search$.pipe(
      debounceTime(500),
      switchMap((term) => {
        setTimeout(() => {
          if (this.dataScrollComponent) {
            this.dataScrollComponent.nativeElement.scrollTop = 0;
          }
        });
        let offset = 0;
        let items: TsEntity[] = [];
        this.isLoaded = false;

        return this.scroll$.pipe(
          filter(() => !this.isLoaded),
          exhaustMap(() => {
            if (!this.queryFn) {
              return EMPTY;
            }

            this.isLoading = true;
            this.changeDetectorRef.markForCheck();

            return this.queryFn({
              limit: PAGE_SIZE,
              offset: offset,
              search: term ?? '',
            }).pipe(
              take(1),
              tap(({ pagination }) => {
                const { offset: pageOffset, total } = pagination;
                offset = (pageOffset || 0) + PAGE_SIZE;

                if (offset >= (total || 0)) {
                  this.isLoaded = true;
                }
              }),
              map(({ items: pageItems }) => {
                items = [...items, ...pageItems];
                return items;
              }),
              finalize(() => (this.isLoading = false))
            );
          })
        );
      })
    );
  }

  public ngAfterViewInit(): void {
    if (this.dropdownList) {
      this.options?.changes
        .pipe(takeUntil(this.destroy$))
        .subscribe((options) => (this.dropdownList.options = options));
    }
  }

  public onScroll(): void {
    this.scroll$.next();
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
