import { TextMaskConfig } from "@topseller/mask";

export const AUTH_TOKEN_NAME = 'authToken';
export const REFRESH_TOKEN_NAME = 'refreshToken';
export const CURRENT_THEME_NAME = 'currentTheme';
export const RNP_HIDDEN_COLUMNS = 'rnp_common_table_hidden_columns';
export const RNP_FILTER_VISIBILITY = 'rnp_is_filter_expanded'

export const DIGIT_REGEXP = /\d/;
export const PHONE_REGEX = /^\+[0-9]{11,12}$/;

// regex для извлечения значения внутри скобок у filter[...]
// применяется при парсинге параметров в адресной строке
export const NAME_FROM_FILTER_REGEX = /^filter\[([^\]]+)\](?:\[([^\]]+)\])?$/;


export const tsPhoneMaskOptions: TextMaskConfig = {
  mask: () => [
    ...'+############'
      .split(``)
      .map((item) => (item === `#` ? DIGIT_REGEXP : item)),
  ],
  guide: false,
} as unknown as TextMaskConfig;

export const getControlNameFromFilter = (filter: string): string => {
  const match = filter.match(NAME_FROM_FILTER_REGEX);
  if (match) {
    // если был сложный объект (выглядел как filter[a][b]),
    // то это превращаем в a_b
    return match[2] ? `${match[1]}_${match[2]}` : match[1];
  }
  return '';
}

// максимальное значение для полей, где указываем кол-во
export const MAX_ITEMS_COUNT = 9999999999;
