import {Inject, Injectable, Optional} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {ApiEntityName, BASE_PATH, Configuration} from "../../data";
import {ToastrService} from "ngx-toastr";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable()
export class ExportDocumentsService {
  protected basePath = 'http://hub.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration,
    private toastr: ToastrService,
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  exportToExcel(entityName: string, filter: string): Observable<{ blob: Blob | null, filename: string }> {
    this.toastr.info('Идет подготовка файла для скачивания. Пожалуйста, ожидайте')
    const apiEntity = ApiEntityName[entityName];
    return this.httpClient.get<Blob>(`${this.basePath}/api/v1/${apiEntity}/excel?${filter}`, {
      observe: 'response',
      responseType: 'blob' as 'json'
    })
      .pipe(
        map(response => {
          const contentDisposition = response.headers.get('content-disposition') || '';
          const matches = /filename[^;=\n]*=['"]?(.*?)['"]?;/.exec(contentDisposition);
          const filename = (matches && matches[1]) ? matches[1] : 'file.xlsx';

          return {blob: response.body, filename};
        })
      );
  }
}
