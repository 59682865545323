import {Inject, Injectable, Optional} from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BASE_PATH } from '../variables';
import {Observable} from "rxjs";
import {ReportPaymentDetails, ReportPayments} from "../model/reports/report-payments";

@Injectable()
export class ReportPaymentApiService {
    protected basePath = 'http://denis.dev.topseller.ru';

    constructor(protected httpClient: HttpClient,
                @Optional() @Inject(BASE_PATH) basePath: string,) {
        if (basePath) {
            this.basePath = basePath;
        }
    }

    createReport(filter: string): Observable<ReportPayments> {
        return this.httpClient.get<ReportPayments>(`${this.basePath}/api/v1/report/payments?${filter}`);
    }

    getReportDetail(reportId: string): Observable<HttpResponse<ReportPaymentDetails>> {
        return this.httpClient.get<ReportPaymentDetails>(`${this.basePath}/api/v1/report/payments/${encodeURIComponent(
            String(reportId)
        )}/detail`, {observe: 'response'});
    }
}
