import { ROLES } from '../restrictions';
import { BillingSubscription } from './billing-subscription';

export type ServiceInfo = {
  category: string;
  name: string;
  icon: string;
  available?: boolean;
  subscriptionInfo?: BillingSubscription;
  description: string;
  requiredRole?: string;
  id: string;
  hidden?: boolean;
  url: string;
  showTariffState?: boolean;
  initialModalTitle?: string;
  isNew?: boolean;
  isBeta?: boolean;
};

//список сервисов, которые не отображаем в проде.
export const productionExcludedServices = ['analytics'];

export const appServices: ServiceInfo[] = [
  {
    id: 'hub',
    category: 'Товары',
    icon: 'hub.svg',
    name: 'Hub',
    description: 'Товароучетная система',
    url: '/hub',
    initialModalTitle: 'Hub (+Connect)',
  },
  {
    id: 'connect',
    category: 'Товары',
    icon: 'connect.svg',
    name: 'Connect',
    description: 'Самая функциональная интеграция с маркетплейсами',
    url: '/connect',
    requiredRole: ROLES.ROLE_ACCOUNT_APPLICATION_VIEW,
    initialModalTitle: 'Hub (+Connect)',
  },
  {
    id: 'pim',
    category: 'Контент',
    icon: 'pim.svg',
    name: 'PIM',
    description: 'Автоматическая выгрузка и управление карточками товаров ',
    url: '/pim',
    requiredRole: ROLES.ROLE_ACCOUNT_APPLICATION_VIEW,
  },
  {
    id: 'analytics',
    category: 'Аналитика',
    icon: 'analytics.svg',
    name: 'Analytics',
    description: 'Скоро...🙂',
    url: '/analytics',
  },
  {
    id: 'rnp',
    category: 'Аналитика',
    icon: 'rnp.svg',
    name: 'Pulse',
    description: 'Внутренняя аналитика',
    url: '/pulse',
    available: true,
    isNew: true,
    isBeta: true,
  },
  {
    id: 'repricer',
    category: 'Продвижение',
    icon: 'repricer.svg',
    name: 'Repricer',
    description: 'Опережайте конкурентов с лучшей ценой',
    url: '/repricer',
  },
  {
    id: 'aitools',
    category: 'Контент',
    icon: 'aiTools.svg',
    name: 'AiTools',
    description: 'Генерация отзывов и описаний через ИИ',
    url: '/ai/import',
  },
  {
    id: 'wbads',
    category: 'Продвижение',
    icon: 'ads.svg',
    name: 'ADS',
    description: 'Автоматизация рекламы и работа с ключевыми словами',
    url: '/wb-ads',
  },
  // Временно скрываем чтобы показать RNP
  // {
  //   id: 'dropship',
  //   category: 'Товары',
  //   icon: 'dropship.svg',
  //   name: 'Dropship',
  //   description: 'Автоматизация остатков по системе дропшип',
  //   url: '/dropship',
  //   available: true,
  // },
];
