<div
  class="position-relative loader-animated"
  [ngClass]="{ loading: isLoading, blur: !skeleton && isLoading }"
  style="height: 100%"
>
  <ng-container *ngIf="!(isLoading && skeleton)">
    <ng-content></ng-content>
  </ng-container>
  <ng-container *ngIf="isLoading">
    <div *ngIf="skeleton; else loader" class="loader-skeleton">
      <ngx-skeleton-loader
        *tsLet="theme$ | async as currentTheme"
        count="25"
        [theme]="{
          'border-radius': '5px',
          height: '38px',
          'background-color': currentTheme === 'dark' ? '#333' : '#f6f6f6',
          'margin-bottom': '5px'
        }"
      ></ngx-skeleton-loader>
    </div>

    <ng-template #loader>
      <div class="loader-container">
        <svg
          id="a"
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          width="48.95"
          height="68"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 50 68"
        >
          <defs>
            <linearGradient
              id="c"
              x1="-154.12"
              y1="339.39"
              x2="-154.12"
              y2="311.94"
              gradientTransform="translate(346.09 678.78) scale(2 -2)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stop-color="#48b798" />
              <stop offset="1" stop-color="#59eac1" />
            </linearGradient>
            <linearGradient
              id="d"
              x1="-167.49"
              y1="332.84"
              x2="-167.49"
              y2="305.39"
              gradientTransform="translate(346.09 678.78) scale(2 -2)"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stop-color="#48b798" />
              <stop offset="1" stop-color="#59eac1" />
            </linearGradient>
          </defs>

          <path
            d="m34.5,6.04l-7.55,40.06c-.86,4.57.77,8.81,5.34,8.81,3.49,0,8.31-2.52,8.97-6.01l7.6-40.05c.87-4.59-4.97-8.85-9.57-8.85-3.51,0-4.14,2.53-4.8,6.04Z"
            style="fill: url(#c); stroke-width: 0px"
          />
          <path
            d="m7.77,19.13L.22,59.19c-.86,4.57.77,8.81,5.34,8.81,3.49,0,8.31-2.52,8.97-6.01l7.6-40.05c.87-4.59-4.97-8.85-9.56-8.85-3.51,0-4.14,2.53-4.8,6.04Z"
            style="fill: url(#d); stroke-width: 0px"
          />
        </svg>
      </div>
    </ng-template>
  </ng-container>
</div>
