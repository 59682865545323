import { MONTHS_IN_YEAR } from './constants';
import { normalizeToIntNumber } from './utils';
import { TsYear } from './year';

export class TsMonth extends TsYear {
  public get daysCount(): number {
    return TsMonth.getMonthDaysCount(this.month, this.isLeapYear);
  }

  public get formattedMonth(): string {
    return String(this.month + 1).padStart(2, `0`);
  }

  public static currentMonth(): TsMonth {
    const nativeDate = new Date();
    return new TsMonth(nativeDate.getFullYear(), nativeDate.getMonth());
  }

  protected static normalizeMonth(month: number): number {
    return normalizeToIntNumber(month, 0, 11);
  }

  constructor(year: number, public readonly month: number) {
    super(year);
  }

  public static getMonthDaysCount(month: number, isLeapYear: boolean): number {
    switch (month) {
      case 1:
        return isLeapYear ? 29 : 28;
      case 3:
      case 5:
      case 8:
      case 10:
        return 30;
      default:
        return 31;
    }
  }

  public append({ year = 0, month = 0 }: Partial<TsMonth>): TsMonth {
    const totalMonths =
      (this.year + year) * MONTHS_IN_YEAR + this.month + month;

    return new TsMonth(
      Math.floor(totalMonths / MONTHS_IN_YEAR),
      totalMonths % MONTHS_IN_YEAR
    );
  }

  public monthBefore(another: TsMonth): boolean {
    return (
      this.yearBefore(another) ||
      (this.yearSame(another) && this.month < another.month)
    );
  }

  public monthAfter(another: TsMonth): boolean {
    return (
      this.yearAfter(another) ||
      (this.yearSame(another) && this.month > another.month)
    );
  }

  public monthSame(another: TsMonth): boolean {
    return this.yearSame(another) && this.month === another.month;
  }
}
