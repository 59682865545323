import { createAction, props } from '@ngrx/store';

import {
  AuthTokenResponse,
  BillingSubscription,
  Restrictions,
} from '../../model';
import { AccountSelectResponse } from '../../model';

export const setAccountSelectLogin = createAction(
  '[Auth] setAccountSelectLogin',
  props<{ authData: AccountSelectResponse }>()
);

export const setAuthTokenLogin = createAction(
  '[Auth] setAuthTokenLogin',
  props<{ authData: AuthTokenResponse }>()
);

export const setAuthRestrictions = createAction(
  '[Auth] setAuthRestrictions',
  props<{ authData: Restrictions }>()
);

export const setSubscriptions = createAction(
  '[Auth] Set Subscriptions',
  props<{ subscriptions: BillingSubscription[] }>()
);
export const updateSubscription = createAction(
  '[Auth] Update Subscription',
  props<{ subscription: BillingSubscription }>()
);

export const setSettingsTableColumns = createAction(
  '[Auth] setSettingsTableColumns',
  props<{ settings: { [key: string]: string } }>()
);

export const setActive = createAction(
  '[Auth] setActive',
  props<{ isSignedUp: boolean }>()
);

export const setImage = createAction(
  '[Auth] setImage',
  props<{ image: string }>()
);

export const setShowHelpOnStart = createAction(
  '[Auth] setShowHelpOnStart',
  props<{ showHelpOnStart: boolean }>()
);

export const setWasShownHelpOnStart = createAction(
  '[Auth] setWasShownHelpOnStart',
  props<{ wasShownHelpOnStart: boolean }>()
);

export const logout = createAction('[Auth] Logout');

export const setIndicatorsSettings = createAction(
  '[Auth] setIndicatorsSettings',
  props<{ settings: string }>()
);

// обновить массив своих собственных ролей.
export const updateOwnRoles = createAction(
  '[Auth] updateOwnRoles',
  props<{ roles: string[] }>()
);
