import { Injectable } from '@angular/core';
import { CurrencyFormatPipe } from '@topseller/ui';
import { DecimalPipe } from '@angular/common';

/**
 Превращает конкретное значение, в значение, которое будет отображаться в ячейке таблицы.
 * */
@Injectable()
export class DataItemToTableValueMapper {
  constructor(
    private numberToCurrencyPipe: CurrencyFormatPipe,
    private decimalPipe: DecimalPipe
  ) {}

  public map(itemKey: string, item: any, type?: string) {
    const key = itemKey as keyof typeof item;
    const data = item[key]!;

    if (data !== null && data !== undefined) {
      switch (type) {
        case 'date':
          return new Date(data).toLocaleString();
        case 'number':
          return this.decimalPipe.transform(data, '1.2-3');
        case 'trimmedNumber':
        case 'progressBar':
        case 'percentDynamic':
          return  this.decimalPipe.transform(data, '1.0-3');
        case 'currency':
          return this.numberToCurrencyPipe?.transform(data);
        case 'percent':
          return `${this.decimalPipe.transform(data, '1.0-3')}%`;
        default:
          break;
      }
    }

    if (typeof data === 'boolean') {
      return data ? 'Да' : 'Нет';
    }

    if (data === null || data === undefined) {
      return '';
    } else if (data.name) {
      return data.name;
    }

    return typeof data === 'string' || typeof data === 'number' ? data : '';
  }
}
