<ts-form-controls-card label="Комментарии и файлы" [isExpandable]="false">

  <div slot="label-content" class="d-none d-md-block">
    <ts-toggle-buttons-group [items]="tabs" (selectItem)="mode = $event" [selectedId]="mode"/>
  </div>

  <div slot="card-actions" *ngIf="mode === 'files'" class="d-flex align-items-center h-100">
    <ts-drop-upload-container [formControl]="filesControl" [formats]="formats" #dnd>
      <div class="d-flex align-items-center gap-3">
        <div class="ts-body-small upload-container ts-btn btn-grey ts-btn-bezeled ts-btn-md" (click)="dnd.openDialog()">
          <span>Выберите файл </span>
          <span class="ts-text-placeholder-color">или перетащите сюда</span>
          <i class="ts-icon ts-icon-arrow-Export text-2lg ts-text-placeholder-color"></i>
        </div>
        <div *ngIf="filesUploading$|async" class="d-flex align-items-center gap-2">
          <span>Загрузка файлов ({{filesUploadingCount$|async}})</span>
          <i class="ts-icon ts-icon-actions-Progress text-xl spinner"></i>
        </div>
      </div>
    </ts-drop-upload-container>
  </div>

  <div tsStretchToBottom [offset]="16">
    <ts-comments-block *ngIf="mode==='comments'"  [entityId]="entityId"></ts-comments-block>


    <ts-files-block
      #filesList
      *ngIf="mode==='files'"
      [entityId]="entityId"
      [entityType]="entityType"
    ></ts-files-block>

  </div>
</ts-form-controls-card>
