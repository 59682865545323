import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HubState, selectMetadata } from '../../store';
import { HubEntity } from '../../data';
import { distinctUntilChanged, Observable, of, take } from 'rxjs';
import { map } from 'rxjs/operators';
import { FilterType } from '@topseller/core';
import { Params } from '@angular/router';
import { FiltersDbService } from './filtersDbService';

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  constructor(
    private store: Store<HubState>,
    private filtersDbService: FiltersDbService
  ) {}

  // filters: { [key: string]: { [key: string]: string } } = {};

  getDefaultFilter(
    entityName: HubEntity | string | undefined
  ): Observable<{ [key: string]: string }> {
    if (!entityName) {
      return of({});
    }
    return this.store.select(selectMetadata).pipe(
      take(1),
      map((meta) => meta!.filter[entityName]),
      map((filterGroups) => {
        const byTargetDictionary: { [key: string]: string } = {};
        for (const filterGroup of filterGroups) {
          if (filterGroup.type === FilterType.DATERANGE && filterGroup.target) {
            filterGroup.filters.forEach((filter) => {
              if (filter.defaultValue !== null) {
                let value = filter.defaultValue.data;
                if (filter.type === 'date') {
                  value = this.convertToLocalTime(value);
                }
                byTargetDictionary[
                  `filter[${filterGroup.target}][${filter.target}]`
                ] = value;
              }
            });
          } else {
            filterGroup.filters.forEach((filter) => {
              if (filter.defaultValue !== null) {
                let value = filter.defaultValue.id;
                if (filter.type === 'date') {
                  value = filter.defaultValue.data;
                  value = this.convertToLocalTime(value);
                }
                byTargetDictionary[`filter[${filter.target}]`] = value;
              }
            });
          }
        }
        return byTargetDictionary;
      })
    );
  }

  async getSavedFilter(
    entityName: HubEntity | string
  ): Promise<{ [key: string]: string } | null> {
    const filterFromDb = await this.filtersDbService.getObject(entityName);
    return filterFromDb || null;
  }

  async saveLastAppliedFilter(
    entityName: HubEntity | string,
    params: Params
  ): Promise<void> {
    if (!entityName) {
      return;
    }
    const { search, searchText } = params;

    // Отфильтруем все ключи, начинающиеся с 'filter['
    const filters = Object.keys(params)
      .filter(key => key.startsWith('filter['))
      .reduce((acc, key) => {
        acc[key] = params[key];
        return acc;
      }, {} as Params);

    // search сохраняется отдельно
    if (search){
      filters['search'] = search
    }
    if (searchText){
      filters['searchText'] = searchText
    }
    await this.filtersDbService.setObject(entityName, filters);
  }

  /**
   * С сервера приходит значение фильтра по умолчанию для дат в часовом поясе +0
   * а на фронте работаем с локальными датами. нужно сделать перевод.
   * **/
  convertToLocalTime(dateStr: string): string {
    const date = new Date(dateStr);

    // Учитываем смещение времени пользователя
    const timezoneOffset = date.getTimezoneOffset(); // это в минутах
    date.setMinutes(date.getMinutes() + timezoneOffset);

    return date.toISOString();
  }
}
