import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Optional,
  Output,
  Self,
  ViewChild,
} from '@angular/core';
import { TsAbstractControl } from '@topseller/cdk/abstract';
import { NgControl } from '@angular/forms';
import { getNativeFocused } from '../utils';

@Component({
  selector: 'ts-input-currency',
  templateUrl: './input-currency.component.html',
  styleUrls: ['./input-currency.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.has-label]': 'label',
  },
})
export class InputCurrencyComponent extends TsAbstractControl<string | null> {
  @ViewChild('input', { static: true, read: ElementRef })
  public inputElement?: ElementRef;

  @Output() valueChange = new EventEmitter<string>();

  @Input() formatMultiplier = 100;
  @Input() precision = 2;

  @Input() cleaner = true;

  @Input()
  public label = '';

  @Input() public placeholder = '';

  @Input() public labelInside = false;

  @Output()
  public readonly focusChanged = new EventEmitter<boolean>();

  @HostBinding('class.disabled')
  @Input()
  public readonly = false;

  constructor(
    @Self() @Optional() ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef
  ) {
    super(ngControl, changeDetectorRef);
  }

  // Эти события слушать обязательно для корректного отслеживания focused
  @HostListener('focusin', ['true'])
  @HostListener('focusout', ['false'])
  public onFocused(focused: boolean) {
    this.focusChanged.emit(focused);
  }

  @HostBinding(`class.focused`)
  public get focused(): boolean {
    const node = this.elementRef.nativeElement;
    const documentRef = node.ownerDocument;

    const element = getNativeFocused(documentRef);
    return !!element && node.contains(element);
  }

  protected getFallbackValue(): string | null {
    return null;
  }

  public get labelRaised(): boolean {
    return this.focused || this.hasValue;
  }

  private get hasValue(): boolean {
    return !(
      this.value === null ||
      this.value === undefined ||
      this.value.toString().trim() === ''
    );
  }

  public onModelChange(value: string): void {
    this.updateValue(value);
    this.valueChange.emit(value);
  }

  public clear() {
    this.updateValue(null);
  }
}
