import { Injectable } from '@angular/core';
import {
  appServices,
  BillingSubscription,
  selectSubscriptions,
  ServiceInfo,
  setShowLoader,
  TopsellerServices,
  SecurityService,
  updateSubscription,
} from '@topseller/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
  catchError,
  concatMap,
  exhaustMap,
  map,
  Observable,
  of,
  tap,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { ServiceInitialModalComponent } from '../../../shared';

/**
 * Guard который проверяет активирован ли сервис, т.е. заходил ли пользователь на страницу сервиса.
 * Показывает модальное окно с предложением активировать сервис, если пользователь не активировал сервис.
 * */
@Injectable()
export class ServiceActivatedGuard {
  constructor(
    private store: Store,
    private dialog: MatDialog,
    private securityService: SecurityService
  ) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    const serviceIdRaw: TopsellerServices = route.data['serviceId'];
    const serviceId =
      serviceIdRaw === TopsellerServices.CONNECT
        ? TopsellerServices.HUB
        : serviceIdRaw;

    return this.store.select<BillingSubscription[]>(selectSubscriptions).pipe(
      exhaustMap((subscriptions: BillingSubscription[]) => {
        const showModal = this.checkNeedShowModalForService(
          serviceId,
          subscriptions
        );

        if (!showModal) {
          this.store.dispatch(setShowLoader({ showLoader: true }));
          return of(true);
        }

        const service: ServiceInfo | undefined = appServices.find(
          (x: ServiceInfo) => x.id === serviceId
        );

        const dialogRef: MatDialogRef<ServiceInitialModalComponent> =
          this.dialog.open(ServiceInitialModalComponent, {
            backdropClass: 'ts-modal-backdrop',
            panelClass: ['ts-modal-panel', 'ts-modal-panel-lg'],
            id: 'ts-modal',
            data: {
              title: service?.initialModalTitle ?? service?.name,
              serviceId: service?.id as TopsellerServices,
            },
          });

        return dialogRef.afterClosed().pipe(
          map((res: unknown) =>
            res && service ? (service?.id as TopsellerServices) : null
          ),

          concatMap((identifier: TopsellerServices | null) => {
            if (identifier) {
              this.store.dispatch(setShowLoader({ showLoader: true }));

              return this.securityService
                .activateProductTrial({ identifier })
                .pipe(
                  tap((subscription: BillingSubscription) => {
                    this.store.dispatch(updateSubscription({ subscription }));
                  }),
                  map(() => true),
                  catchError(() => {
                    this.store.dispatch(setShowLoader({ showLoader: false }));

                    return of(false);
                  })
                );
            }

            return of(false);
          })
        );
      })
    );
  }

  private checkNeedShowModalForService(
    serviceId: TopsellerServices,
    subscriptions: BillingSubscription[] = []
  ): boolean {
    const skipSubscriptionServices = [
      TopsellerServices.PIM,
      TopsellerServices.ANALYTICS,
    ];

    if (skipSubscriptionServices.includes(serviceId)) {
      return false;
    }

    const subscriptionInfo = subscriptions.find(
      (s: BillingSubscription) => s.product!.identifier === serviceId
    );

    return !subscriptionInfo?.tariff || false;
  }
}
