/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 22.1205.1237
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import {
  BatchDelete,
  Comment,
  Demand,
  Image,
  Log,
  Order,
  Paginated,
  Payment,
  Product,
  Recall,
} from '../model/models';

import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';
import { DocumentUntrashService } from "../../common/services";
import { TemplateRequest } from "../model/templateRequest";

@Injectable()
export class OrderService implements DocumentUntrashService<Order>{
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * @param consumes string[] mime-types
   * @return true: consumes contains 'multipart/form-data', false: otherwise
   */
  private canConsumeForm(consumes: string[]): boolean {
    const form = 'multipart/form-data';
    for (const consume of consumes) {
      if (form === consume) {
        return true;
      }
    }
    return false;
  }

  /**
   * Удалить заказ
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAppOrderDelete(
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling deleteAppOrderDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/api/v1/orders/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Удалить позицию заказа
   *
   * @param orderId
   * @param productId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAppOrderDeleteorderproduct(
    orderId: string,
    productId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<any>;
  public deleteAppOrderDeleteorderproduct(
    orderId: string,
    productId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<any>>;
  public deleteAppOrderDeleteorderproduct(
    orderId: string,
    productId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<any>>;
  public deleteAppOrderDeleteorderproduct(
    orderId: string,
    productId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling deleteAppOrderDeleteorderproduct.'
      );
    }

    if (productId === null || productId === undefined) {
      throw new Error(
        'Required parameter productId was null or undefined when calling deleteAppOrderDeleteorderproduct.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}/api/v1/orders/${encodeURIComponent(
        String(orderId)
      )}/order-products/${encodeURIComponent(String(productId))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Все изображения заказа
   *
   * @param orderId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppOrderImageall(
    orderId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Image>>;
  public getAppOrderImageall(
    orderId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Image>>>;
  public getAppOrderImageall(
    orderId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Image>>>;
  public getAppOrderImageall(
    orderId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling getAppOrderImageall.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Image>>(
      'get',
      `${this.basePath}/api/v1/orders/${encodeURIComponent(
        String(orderId)
      )}/images`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Основное изображение заказа
   *
   * @param orderId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppOrderImagedefault(
    orderId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Image>;
  public getAppOrderImagedefault(
    orderId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Image>>;
  public getAppOrderImagedefault(
    orderId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Image>>;
  public getAppOrderImagedefault(
    orderId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling getAppOrderImagedefault.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Image>(
      'get',
      `${this.basePath}/api/v1/orders/${encodeURIComponent(
        String(orderId)
      )}/image`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Список заказов покупателю
   *
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param filter Коллекция фильтров
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppOrderIndex(
    limit?: number,
    offset?: number,
    search?: string,
    filter?: string,
    sortName?: string,
    sortDir?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<Order>>;
  public getAppOrderIndex(
    limit?: number,
    offset?: number,
    search?: string,
    filter?: string,
    sortName?: string,
    sortDir?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Paginated<Order>>>;
  public getAppOrderIndex(
    limit?: number,
    offset?: number,
    search?: string,
    filter?: string,
    sortName?: string,
    sortDir?: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Paginated<Order>>>;
  public getAppOrderIndex(
    limit?: number,
    offset?: number,
    search?: string,
    filter?: string,
    sortName?: string,
    sortDir?: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', <any>search);
    }
    if(sortName !== undefined && sortDir !== null) {
      queryParameters = queryParameters.set(`sort[${sortName}]`, <any>sortDir)
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<Order>>(
      'get',
      `${this.basePath}/api/v1/orders?${filter ?? ''}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Состав заказа покупателя
   *
   * @param orderId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppOrderListorderproduct(
    orderId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Product>>;
  public getAppOrderListorderproduct(
    orderId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Product>>>;
  public getAppOrderListorderproduct(
    orderId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Product>>>;
  public getAppOrderListorderproduct(
    orderId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling getAppOrderListorderproduct.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Product>>(
      'get',
      `${this.basePath}/api/v1/orders/${encodeURIComponent(
        String(orderId)
      )}/order-products`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Список записей в логах
   *
   * @param orderId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppOrderLogindex(
    orderId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Array<Log>>;
  public getAppOrderLogindex(
    orderId: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Array<Log>>>;
  public getAppOrderLogindex(
    orderId: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Array<Log>>>;
  public getAppOrderLogindex(
    orderId: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling getAppOrderLogindex.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Array<Log>>(
      'get',
      `${this.basePath}/api/v1/orders/${encodeURIComponent(
        String(orderId)
      )}/log`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Сгенерировать наименование заказа
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppOrderName(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<string>;
  public getAppOrderName(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public getAppOrderName(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public getAppOrderName(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<string>(
      'get',
      `${this.basePath}/api/v1/orders/name`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Сгенерировать номер заказа
   *
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppOrderNumber(
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<string>;
  public getAppOrderNumber(
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<string>>;
  public getAppOrderNumber(
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<string>>;
  public getAppOrderNumber(
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<string>(
      'get',
      `${this.basePath}/api/v1/orders/number`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Принять весь товар в доставку
   *
   * @param orderId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deliverAll(
    orderId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Order> {
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling getAppOrderDeliverall.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Order>(
      'get',
      `${this.basePath}/api/v1/orders/${encodeURIComponent(
        String(orderId)
      )}/deliver/all`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Платеж за весь заказ
   *
   * @param orderId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public payAll(
    orderId: string,
    body?: Payment,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Order> {
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling postAppOrderPayall.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Order>(
      'post',
      `${this.basePath}/api/v1/orders/${encodeURIComponent(
        String(orderId)
      )}/pay/all`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Заказ
   *
   * @param id
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppOrderView(
    id: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Order>;
  public getAppOrderView(
    id: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Order>>;
  public getAppOrderView(
    id: string,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Order>>;
  public getAppOrderView(
    id: string,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (id === null || id === undefined) {
      throw new Error(
        'Required parameter id was null or undefined when calling getAppOrderView.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Order>(
      'get',
      `${this.basePath}/api/v1/orders/${encodeURIComponent(String(id))}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отредактировать заказ
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchAppOrderUpdate(
    body?: Order,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Order>;
  public patchAppOrderUpdate(
    body?: Order,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Order>>;
  public patchAppOrderUpdate(
    body?: Order,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Order>>;
  public patchAppOrderUpdate(
    body?: Order,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Order>(
      'patch',
      `${this.basePath}/api/v1/orders`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Изменить комментарий к заказу
   *
   * @param orderId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchAppOrderUpdatecomment(
    orderId: string,
    body?: Comment,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Log>;
  public patchAppOrderUpdatecomment(
    orderId: string,
    body?: Comment,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Log>>;
  public patchAppOrderUpdatecomment(
    orderId: string,
    body?: Comment,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Log>>;
  public patchAppOrderUpdatecomment(
    orderId: string,
    body?: Comment,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling patchAppOrderUpdatecomment.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Log>(
      'patch',
      `${this.basePath}/api/v1/orders/${encodeURIComponent(
        String(orderId)
      )}/comment`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Изменить позицию заказа покупателя
   *
   * @param orderId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public patchAppOrderUpdateorderproduct(
    orderId: string,
    body?: Product,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Product>;
  public patchAppOrderUpdateorderproduct(
    orderId: string,
    body?: Product,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Product>>;
  public patchAppOrderUpdateorderproduct(
    orderId: string,
    body?: Product,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Product>>;
  public patchAppOrderUpdateorderproduct(
    orderId: string,
    body?: Product,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling patchAppOrderUpdateorderproduct.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Product>(
      'patch',
      `${this.basePath}/api/v1/orders/${encodeURIComponent(
        String(orderId)
      )}/order-products`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить заказ
   *
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppOrderCreate(
    body?: Order,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Order>;
  public postAppOrderCreate(
    body?: Order,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Order>>;
  public postAppOrderCreate(
    body?: Order,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Order>>;
  public postAppOrderCreate(
    body?: Order,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Order>(
      'post',
      `${this.basePath}/api/v1/orders`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Добавить комментарий к заказу
   *
   * @param orderId
   * @param body
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppOrderCreatecomment(
    orderId: string,
    body?: Comment,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Log>;
  public postAppOrderCreatecomment(
    orderId: string,
    body?: Comment,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Log>>;
  public postAppOrderCreatecomment(
    orderId: string,
    body?: Comment,
    observe?: 'events',
    reportProgress?: boolean
  ): Observable<HttpEvent<Log>>;
  public postAppOrderCreatecomment(
    orderId: string,
    body?: Comment,
    observe: any = 'body',
    reportProgress: boolean = false
  ): Observable<any> {
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling postAppOrderCreatecomment.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Log>(
      'post',
      `${this.basePath}/api/v1/orders/${encodeURIComponent(
        String(orderId)
      )}/comment`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отгрузка на основе заказа.
   * Создать документ отгрузки товара на основе заказа покупателя.
   * @param orderId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public addTransfer(
    orderId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Demand> {
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling postAppOrderDemand.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Demand>(
      'post',
      `${this.basePath}/api/v1/orders/${encodeURIComponent(
        String(orderId)
      )}/demand`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Возврат от покупателя на основе заказа.
   * Создать возврат товара от покупателя на основе отгруженных товаров в заказе.
   * @param orderId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public returnTransfer(
    orderId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Recall> {
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling postAppOrderRecall.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Recall>(
      'post',
      `${this.basePath}/api/v1/orders/${encodeURIComponent(
        String(orderId)
      )}/recall`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
  /**
   * Частичный платеж
   */
  public addPayment(
    orderId: string,
    body?: Payment,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Payment> {
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling postAppOrderPayment.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Payment>(
      'post',
      `${this.basePath}/api/v1/orders/${encodeURIComponent(
        String(orderId)
      )}/payment`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Частичный возврат платежа
   */
  public returnPayment(
    orderId: string,
    body?: Payment,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Payment> {
    if (orderId === null || orderId === undefined) {
      throw new Error(
        'Required parameter orderId was null or undefined when calling postAppOrderRefund.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = ['application/json'];
    const httpContentTypeSelected: string | undefined =
      this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Payment>(
      'post',
      `${this.basePath}/api/v1/orders/${encodeURIComponent(
        String(orderId)
      )}/refund`,
      {
        body: body,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  public getUntrash(id: string) {
    return this.httpClient.get<Order>(
      `${this.basePath}/api/v1/trash/entity/${id}/restore`
    );
  }

  /**
   * Удалить несколько документов продаж
   */
  public batchDeleteOrders(ids: { ids: string[] }): Observable<BatchDelete> {
    return this.httpClient.post(
      `${this.basePath}/api/v1/orders/batch-delete`,
      ids
    );
  }

  public template(template: TemplateRequest){
    return this.httpClient.patch(`${this.basePath}/api/v1/orders/template`,template);
  }
}
