import {catchError, Observable, of, retryWhen, throwError, timer} from "rxjs";
import {mergeMap} from "rxjs/operators";
import { HttpErrorResponse, HttpResponse } from "@angular/common/http";

export interface RetryConfig {
  maxRetryAttempts: number;
  retryDelay: number;
  includedCodes: number[];
}

const defaultRetryConfig: RetryConfig = {
  maxRetryAttempts: 10,
  retryDelay: 1000,
  includedCodes: [202]
};

/**
 * Оператор, который можно встраивать в пайпу RxJS для повторного выполнения запросов, которые вернули 202 код
 * Для того, чтобы это работало, 202 надо кинуть как ошибку, которая обработается в retryWhen с помощью genericRetryStrategy
 * */
export function handleRetryOn202(config: Partial<RetryConfig> = {}) {
  const finalConfig: RetryConfig = { ...defaultRetryConfig, ...config };
  return (source: Observable<HttpResponse<any>>) => source.pipe(
    mergeMap(response =>
      response.status === 202
        ? throwError(
          () =>
            new HttpErrorResponse({
              status: 202,
              statusText: 'not ready',
            })
        )
        : of(response.body)
    ),
    retryWhen(genericRetryStrategy(finalConfig)),
  );
}


/**
 * Функция, которая позволяет повторить выполние Observable
 * По умолчанию будет 10 попыток раз в секунду. Повтор произойдет если HttpErrorResponse имеет статус 202
 */
export const genericRetryStrategy = ({ maxRetryAttempts, includedCodes }: RetryConfig) => (attempts: Observable<any>) => {
  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      if (
        retryAttempt <= maxRetryAttempts &&
        includedCodes.find(e => e === error.status)
      ) {
        // Рассчитываем задержку на основе номера попытки
        const retryDelay = calculateRetryDelay(retryAttempt);
        return timer(retryDelay);
      }
      return throwError(error);
    }),
  );
};

/**
 * Функция для расчета задержки между повторными попытками на основе номера попытки.
 */
function calculateRetryDelay(attempt: number): number {
  if (attempt === 1) {
    return 2000; // 2 секунды
  } else if (attempt >= 2 && attempt <= 4) {
    return 5000; // 5 секунд
  } else if (attempt >= 5 && attempt <= 7) {
    return 10000; // 10 секунд
  } else {
    return 15000; // 15 секунд
  }
}
