import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Record, TableFilter } from '@topseller/core';

import { FormGroup, ReactiveFormsModule } from '@angular/forms';

import { TsEntityListModule } from '../../entity-list';
import { Observable } from 'rxjs';
import { FilterDataService } from '../../table';

import {
  TsSelectOptionModule,
  TsDataListModule,
  InputDateTimeModule,
  TsSelectModule,
  TsInputTextModule,
  IdentityMatcher,
  StringHandler,
} from '@topseller/ui';

import { ControlErrorModule } from '../../control-error';
import { MultiSelectModule } from '../../multi-select';

@Component({
  selector: 'ts-filter-control',
  standalone: true,
  imports: [
    CommonModule,
    TsInputTextModule,
    ReactiveFormsModule,
    InputDateTimeModule,
    TsSelectModule,
    TsEntityListModule,
    TsSelectOptionModule,
    ControlErrorModule,
    TsDataListModule,
    MultiSelectModule,
  ],
  templateUrl: './filter-control.component.html',
  styleUrls: ['./filter-control.component.scss'],
})
export class FilterControlComponent {
  @Input() filter!: TableFilter;
  @Input() entityName?: string;
  @Input() form!: FormGroup;

  constructor(private filterDataService: FilterDataService) {}

  public recordIdentityMatcher: IdentityMatcher<Record> = (
    { id: previousId },
    { id: nextId }
  ) => previousId === nextId;

  public recordStringify: StringHandler<Record> = (item) => item.name;

  public filterValuesQueryFromApiFn(target: string) {
    return this.filterDataService.filterValuesQueryFromApiFn(target);
  }

  public filterValuesFromBaseDataFn(target: string): Observable<any> {
    return this.filterDataService.filterValuesFromBaseDataFn(
      target,
      this.entityName!
    );
  }
}
