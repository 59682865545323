import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';

import { TsLetDirective } from '@topseller/cdk/directives';

import { LoaderAnimatedComponent } from './loader-animated.component';

@NgModule({
  declarations: [LoaderAnimatedComponent],
  imports: [CommonModule, NgxSkeletonLoaderModule, TsLetDirective],

  exports: [LoaderAnimatedComponent],
})
export class LoaderAnimatedModule {}
