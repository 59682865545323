import { HubEntity } from "../../data";

export function getEntityLink(entityName: HubEntity): string {
  switch (entityName) {
    case HubEntity.PAYMENT:
      return '/hub/money/payment';
    case HubEntity.ORDER:
      return '/hub/sale/order';
    case HubEntity.PURCHASE:
      return '/hub/supply/invoice';
    case HubEntity.POSTING:
      return '/hub/goods-accounting/postings';
    case HubEntity.WRITE_OFF:
      return '/hub/goods-accounting/write-off';
    case HubEntity.MOVEMENT:
      return '/hub/goods-accounting/movements';
    case HubEntity.INVENTORY:
      return '/hub/goods-accounting/inventory';
    case HubEntity.REVERT:
      return '/hub/supply/revert';
    case HubEntity.RECEIVE:
      return '/hub/supply/receive';
    case HubEntity.DEMAND:
      return '/hub/sale/demand';
    case HubEntity.RECALL:
      return '/hub/sale/recall';
    case HubEntity.COMMISSIONER_DEMAND:
      return '/hub/com/demands';
    default:
      return '';
  }
}
