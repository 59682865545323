import {DBSchema, IDBPDatabase, openDB} from 'idb';
import {Injectable} from '@angular/core';
import {Store} from '@ngrx/store';
import {selectEmployee, selectUser} from '@topseller/core';

interface UserFilter {
  entityName: string;
  value: { [key: string]: string };
}

interface MyDB extends DBSchema {
  userFilters: {
    key: string;
    value: UserFilter;
  };
}

@Injectable({
  providedIn: 'root',
})
export class FiltersDbService {
  private dbPromise?: Promise<IDBPDatabase<MyDB>>;

  constructor(private store: Store<any>) {
    this.store.select(selectEmployee).subscribe((res) => {
      this.initDB(res.id);
    });
  }

  private async initDB(user_id: string) {
    this.dbPromise = openDB(`${user_id}_filters`, 1, {
      upgrade(db) {
        db.createObjectStore('userFilters', {keyPath: 'entityName'});
      },
    });
  }

  public async getObject(
    entityName: string
  ): Promise<{ [key: string]: string } | undefined> {
    const db = await this.dbPromise;
    try {
      const userObject = await db!.get('userFilters', entityName);
      return userObject?.value;
    } catch {
      return undefined;
    }
  }

  public async setObject(
    entityName: string,
    value: { [key: string]: string }
  ): Promise<void> {
    const db = await this.dbPromise;
    await db!.put('userFilters', {entityName, value});
  }
}
