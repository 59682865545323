<ts-modal-dialog-header [title]="data.title"></ts-modal-dialog-header>
<div class="modal-content-wrapper d-flex flex-column gap-3 p-3">
  <div class="annotation-container">
    <span class="ts-icon ts-icon-actions-info-circle text-2lg"></span>
    <div>
      <div>
        При нажатии на кнопку "Начать пробный период" - он активируется на 14
        дней.
      </div>
      <br />
      <div>
        По <a href="tel:+74958186165">+7 (495) 818-61-65</a> вы можете связаться
        с Отделом продаж (доб. 1) и Тех. поддержкой (доб. 2). Также в правом
        верхнем углу по кнопке "Помощь" всегда доступен чат и мы с радостью
        подскажем по любому вопросу
      </div>
    </div>
  </div>

  <ng-container [ngSwitch]="data.serviceId">
    <ng-container *ngSwitchCase="tsServices.HUB">
      <ng-template [ngTemplateOutlet]="hub"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="tsServices.CONNECT">
      <ng-template [ngTemplateOutlet]="hub"></ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="tsServices.DROPSHIP">
      <ng-template
        [ngTemplateOutlet]="dropship"
        [ngTemplateOutletContext]="{ info: servicesHelpInfo[data.serviceId] }"
      ></ng-template>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-template
        [ngTemplateOutlet]="defaultService"
        [ngTemplateOutletContext]="{ info: servicesHelpInfo[data.serviceId] }"
      ></ng-template>
    </ng-container>
  </ng-container>
</div>
<mat-dialog-actions>
  <div class="d-flex gap-3 justify-content-end w-100">
    <button class="ts-btn primary ts-btn-md ts-btn-filled" (click)="save()">
      Начать пробный период
    </button>
    <button
      class="ts-btn primary ts-btn-md ts-btn-bezeled"
      type="button"
      (click)="close()"
    >
      Отмена
    </button>
  </div>
</mat-dialog-actions>

<ng-template #hub>
  <ng-template
    [ngTemplateOutlet]="defaultService"
    [ngTemplateOutletContext]="{ info: servicesHelpInfo[tsServices.HUB] }"
  ></ng-template>

  <div class="mt-3">
    <ng-template
      [ngTemplateOutlet]="defaultService"
      [ngTemplateOutletContext]="{ info: servicesHelpInfo[tsServices.CONNECT] }"
    ></ng-template>
  </div>
</ng-template>

<ng-template #dropship let-info="info">
  <span [innerHTML]="info.description | safeHtml"></span>
</ng-template>

<ng-template #defaultService let-info="info">
  <div class="d-flex flex-column flex-md-row gap-3">
    <div class="flex-1">
      <span [innerHTML]="info.description | safeHtml"></span>
      <a [href]="info.helpUrl | safeUrl" target="_blank">Подробнее</a>
      <br />
      <br />
    </div>
    <div class="service-modal-video-container">
      <iframe
        [src]="info.modalVideoUrl | safeUrl"
        width="100%"
        height="100%"
        allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</ng-template>
