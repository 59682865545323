/**
 * TOPSELLER HUB API
 * Topseller HUB backend API
 *
 * OpenAPI spec version: 24.0731.0824
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */ /* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { ReportAbcDetail } from '../model/report-abc-detail';
import { ReportAbc } from '../model/report-abc';

import { BASE_PATH } from '../variables';
import { Configuration } from '../configuration';
import { Paginated } from '../model/paginated';

@Injectable()
export class ReportAbcService {
  protected basePath = 'http://hub.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  /**
   * Сгенерировать по параметрам
   * Сгенерировать отчет по параметрам
   * @param filterDatetimeFrom **Дата, начало периода:**
   * @param filterDatetimeTo **Дата, конец периода:**
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public createReport(
    filter?:string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ReportAbc> {

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<ReportAbc>(
      'get',
      `${this.basePath}/api/v1/report/abc?${filter??''}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Детализация отчета
   * Детализация отчета
   * @param reportId
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param sort Сортировка ?sort[type]&#x3D;desc&amp;sort[name]&#x3D;asc
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAbcReportDetails(
    reportId: string,
    limit?: number,
    offset?: number,
    sortName?: string,
    sortDir?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<ReportAbcDetail>>;
  public getAbcReportDetails(
    reportId: string,
    limit?: number,
    offset?: number,
    sortName?: string,
    sortDir?: string,
    observe?: 'response',
    reportProgress?: boolean
  ): Observable<HttpResponse<Paginated<ReportAbcDetail>>>;
  public getAbcReportDetails(
    reportId: string,
    limit?: number,
    offset?: number,
    sortName?: string,
    sortDir?: string,
    observe: any = 'body',
    reportProgress = false
  ): Observable<any> {
    if (reportId === null || reportId === undefined) {
      throw new Error(
        'Required parameter reportId was null or undefined when calling getAppV1ReportsReportabcReportdetail.'
      );
    }

    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', <any>limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', <any>offset);
    }

    if (sortName) {
      queryParameters = queryParameters.set(
        `sort[${sortName}]`,
        sortDir || 'ASC'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    return this.httpClient.request<Paginated<ReportAbcDetail>>(
      'get',
      `${this.basePath}/api/v1/report/abc/${encodeURIComponent(
        String(reportId)
      )}/detail`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Отчет по Id
   * Получить отчет по Id
   * @param reportId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAbcReport(
    reportId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<ReportAbc> {
    if (reportId === null || reportId === undefined) {
      throw new Error(
        'Required parameter reportId was null or undefined when calling getAppV1ReportsReportabcView.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<ReportAbc>(
      'get',
      `${this.basePath}/api/v1/report/abc/${encodeURIComponent(
        String(reportId)
      )}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }
}
