import {
  AfterViewChecked,
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnInit,
  Renderer2,
} from '@angular/core';

@Directive({
  selector: '[tsStretchToBottom]',
  standalone: true,
})
export class StretchToBottomDirective implements OnInit, AfterViewChecked {
  constructor(private el: ElementRef, private renderer: Renderer2) {}
  @Input() offset = 0;
  ngOnInit(): void {
    this.setHeight();
  }

  ngAfterViewChecked() {
    this.setHeight();
  }

  @HostListener('window:resize') onResize() {
    this.setHeight(); // Пересчитываем высоту при изменении размеров окна
  }

  private setHeight(): void {
    const rect = this.el.nativeElement.getBoundingClientRect();
    const topOffset = rect.top + this.offset; // Расстояние от элемента до верхнего края экрана
    const height = `calc(100vh - ${topOffset}px)`;

    // Устанавливаем вычисленную высоту элементу
    this.renderer.setStyle(this.el.nativeElement, 'height', height);
  }
}
