import {Inject, Injectable, Optional} from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import {Configuration} from "../configuration";
import {BASE_PATH} from '../variables';
import {Observable} from "rxjs";
import {Paginated} from "@topseller/core";
import {PrintFormResponse, PrintFormType} from "../model/printFormType";

@Injectable()
export class PrintFormsApiService {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

  getPrintForms(entity: string): Observable<Paginated<PrintFormType>> {
    return this.httpClient.get<Paginated<PrintFormType>>(`${this.basePath}/api/v1/printforms?filter[entity]=${entity}`);
  }

  getDocument(entity: string, entityId: string, formId: string): Observable<PrintFormResponse> {
    const body = {
      data: {
        [entity]: {
          id: entityId
        }
      },
      output: 'json'
    };

    return this.httpClient.post<PrintFormResponse>(`${this.basePath}/api/v1/printforms/reader/identifier/${formId}`, body);
  }
}
