import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Configuration, File as AttachedFile, FileStatsModel, Paginated } from '../model';
import { CustomHttpUrlEncodingCodec } from '../encoder';
import { BASE_PATH } from '../tokens';
import { Batch } from "../model/batch";
import { PaginatedFile } from "../model/paginated-file";

@Injectable()
export class FileService {
  protected basePath = 'http://denis.dev.topseller.ru';
  public defaultHeaders = new HttpHeaders();
  public configuration = new Configuration();

  public isLoadingImg$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    protected httpClient: HttpClient,
    @Optional() @Inject(BASE_PATH) basePath: string,
    @Optional() configuration: Configuration
  ) {
    if (basePath) {
      this.basePath = basePath;
    }
    if (configuration) {
      this.configuration = configuration;
      this.basePath = basePath || configuration.basePath || this.basePath;
    }
  }

    public postFile(entity: string, file: File, entityId?: string): Observable<AttachedFile> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    if (entity !== undefined && entity !== null) {
      queryParameters = queryParameters.set('entity', entity);
    }

    if (entityId !== undefined && entityId !== null) {
      queryParameters = queryParameters.set('entityId', entityId);
    }

    const formData = new FormData();

    formData.append('file', file, file.name)

    return this.httpClient.post<AttachedFile>(
      `${this.basePath}files`,
      formData,
      { params: queryParameters }
    );
  }

  /**
   * Добавить несколько файлов
   * Добавить несколько файлов
   * @param entity Тип объекта
   * @param files
   * @param entityId ID объекта
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppFileBulkcreateForm(entity: string, files?: File[], entityId?: string, observe?: 'body', reportProgress?: boolean): Observable<PaginatedFile[]> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });

    if (entity !== undefined && entity !== null) {
      queryParameters = queryParameters.set('entity', entity);
    }

    if (entityId !== undefined && entityId !== null) {
      queryParameters = queryParameters.set('entityId', entityId);
    }

    if (entity === null || entity === undefined) {
      throw new Error('Required parameter entity was null or undefined when calling postAppFileBulkcreate.');
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    const formData = new FormData();

    if (files) {
      for (const file of files) {
        formData.append('files[]', file, file.name);
      }
    }

    return this.httpClient.request<PaginatedFile[]>('post',`${this.basePath}files/bulk-create`,
      {
        body: formData,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Список файлов
   *
   * @param limit Кол-во, выводимое на страницу
   * @param offset Смещение указателя
   * @param filter Коллекция фильтров
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppFileIndex(
    limit?: number,
    offset?: number,
    search?: string,
    filter?: Record<string, string | number | boolean>,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<AttachedFile>> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', offset);
    }
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', search);
    }
    if (filter) {
      Object.keys(filter).forEach((key) => {
        queryParameters = queryParameters.append(`filter[${key}]`, filter[key]);
      });
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<AttachedFile>>(
      'get',
      `${this.basePath}files`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }


  public filterFiles(
    limit?: number,
    offset?: number,
    search?: string,
    filter?: string,
    sortName?: string,
    sortDir?: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<Paginated<AttachedFile>> {
    let queryParameters = new HttpParams({
      encoder: new CustomHttpUrlEncodingCodec(),
    });
    if (limit !== undefined && limit !== null) {
      queryParameters = queryParameters.set('limit', limit);
    }
    if (offset !== undefined && offset !== null) {
      queryParameters = queryParameters.set('offset', offset);
    }
    if (search !== undefined && search !== null) {
      queryParameters = queryParameters.set('search', search);
    }

    if(sortName !== undefined && sortDir !== null) {
      queryParameters = queryParameters.set(`sort[${sortName}]`, <any>sortDir)
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = ['application/json'];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<Paginated<AttachedFile>>(
      'get',
      `${this.basePath}files?${filter??''}`,
      {
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Удалить комментарий
   *
   * @param fileId
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public deleteAppFileDelete(
    fileId: string,
    observe?: 'body',
    reportProgress?: boolean
  ): Observable<void> {
    if (fileId === null || fileId === undefined) {
      throw new Error(
        'Required parameter fileId was null or undefined when calling deleteAppCommentaryDelete.'
      );
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken =
        typeof this.configuration.accessToken === 'function'
          ? this.configuration.accessToken()
          : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [];
    const httpHeaderAcceptSelected: string | undefined =
      this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [];

    return this.httpClient.request<any>(
      'delete',
      `${this.basePath}files/${fileId}`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress,
      }
    );
  }

  /**
   * Удалить несколько файлов
   * Удалить несколько файлов
   * @param body
   * @param filter Коллекция фильтров
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppFileBatchdelete(
    body?: { ids: string[] },
    filter?: Array<string>,
    observe?: 'body',
    reportProgress?: boolean): Observable<Batch>;
  public postAppFileBatchdelete(
    body?: { ids: string[] },
    filter?: Array<string>,
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<Batch>>;
  public postAppFileBatchdelete(
    body?: { ids: string[] },
    filter?: Array<string>,
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<Batch>>;
  public postAppFileBatchdelete(
    body?: { ids: string[] },
    filter?: Array<string>,
    observe: any = 'body',
    reportProgress: boolean = false ): Observable<any> {

    let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
    if (filter) {
      filter.forEach((element) => {
        queryParameters = queryParameters.append('filter', <any>element);
      })
    }

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
      'application/json'
    ];
    const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
    if (httpContentTypeSelected != undefined) {
      headers = headers.set('Content-Type', httpContentTypeSelected);
    }

    return this.httpClient.request<Batch>('post',`${this.basePath}files/batch-delete`,
      {
        body: body,
        params: queryParameters,
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Удалить все файлы аккаунта
   * Удалить все файлы аккаунта
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public postAppFileBatchDeleteAll(
    observe?: 'body',
    reportProgress?: boolean): Observable<Batch>;
  public postAppFileBatchDeleteAll(
    observe?: 'response',
    reportProgress?: boolean): Observable<HttpResponse<Batch>>;
  public postAppFileBatchDeleteAll(
    observe?: 'events',
    reportProgress?: boolean): Observable<HttpEvent<Batch>>;
  public postAppFileBatchDeleteAll(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<Batch>('post',`${this.basePath}files/batch-delete-all`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }

  /**
   * Статистика
   * Статистика
   * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
   * @param reportProgress flag to report request and response progress.
   */
  public getAppFileStats(observe?: 'body', reportProgress?: boolean): Observable<FileStatsModel>;
  public getAppFileStats(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<FileStatsModel>>;
  public getAppFileStats(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<FileStatsModel>>;
  public getAppFileStats(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

    let headers = this.defaultHeaders;

    // authentication (bearerAuth) required
    if (this.configuration.accessToken) {
      const accessToken = typeof this.configuration.accessToken === 'function'
        ? this.configuration.accessToken()
        : this.configuration.accessToken;
      headers = headers.set('Authorization', 'Bearer ' + accessToken);
    }
    // to determine the Accept header
    const httpHeaderAccepts: string[] = [
      'application/json'
    ];
    const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
    if (httpHeaderAcceptSelected != undefined) {
      headers = headers.set('Accept', httpHeaderAcceptSelected);
    }

    // to determine the Content-Type header
    const consumes: string[] = [
    ];

    return this.httpClient.request<FileStatsModel>('get',`${this.basePath}files/stats`,
      {
        withCredentials: this.configuration.withCredentials,
        headers: headers,
        observe: observe,
        reportProgress: reportProgress
      }
    );
  }
}
