import {
  AfterContentChecked,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { TextMaskConfig } from '@topseller/mask';

import { getNativeFocused } from '../utils';
import { TsCustomContentComponent } from '../common';
import { ClipboardService } from 'ngx-clipboard';
import { Subject, throttleTime } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

// отступ справа для лейбла, чтобы он не упирался в край контрола
const INPUT_LABEL_RIGHT_OFFSET = 32;
const INPUT_LABEL_MIN_WIDTH = 100;

@Component({
  selector: 'ts-base-input',
  templateUrl: './base-input.component.html',
  styleUrls: ['./base-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  // eslint-disable-next-line @angular-eslint/no-host-metadata-property
  host: {
    '[class.has-label]': 'label',
    '[class.label-inside]': 'labelInside',
  },
})
export class BaseInputComponent implements AfterContentChecked {
  @ViewChild('input', { static: false, read: ElementRef })
  public inputElement?: ElementRef<HTMLInputElement>;

  @ViewChild('tsInputLabel', { static: false, read: ElementRef })
  public inputLabel?: ElementRef<HTMLDivElement>;

  @ContentChild(TsCustomContentComponent, { static: false })
  public customContent?: TsCustomContentComponent;

  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('mask') public maskExpression: string | undefined | null = null;

  @Input() public tsMask: TextMaskConfig | null = null;

  @Input() leadingIcon = '';
  @Input() trailingIcon = '';

  @HostBinding(`class.multirow`)
  @Input()
  multirow = false;

  @Input()
  public icon = '';

  @Input()
  public label = '';

  /**
   * находится ли лейбл внутри инпута, или же всплывает наверх.
   * **/
  @Input()
  labelInside = false;

  @Input()
  public placeholder = '';

  @Input()
  public value = '';

  @Input()
  public cleaner = true;

  @Input()
  public copier = false;

  @Input() public readonly = false;

  @HostBinding(`class.ts-disabled`)
  @Input()
  public disabled = false;

  @Output()
  public readonly valueChange = new EventEmitter<string>();

  @Output()
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  public readonly onClear = new EventEmitter<void>();

  @Output()
  public readonly focusChanged = new EventEmitter<boolean>();

  @Output()
  readonly trailingIconClick = new EventEmitter<void>();

  @Input() trailingIconClickable = false;

  // Эти события слушать обязательно для корректного отслеживания focused
  @HostListener('focusin', ['true'])
  @HostListener('focusout', ['false'])
  public onFocused(focused: boolean) {
    this.focusChanged.emit(focused);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.recalculateLabelWidth$.next();
  }

  @HostBinding(`class.focused`)
  public get focused(): boolean {
    const node = this.elementRef.nativeElement;
    const documentRef = node.ownerDocument;

    const element = getNativeFocused(documentRef);
    return !!element && node.contains(element) && !this.disabled;
  }

  @HostBinding('style.--border-right.rem')
  public get borderRight(): number {
    return (
      (this.icon ? 1.5 : 0) +
      (this.hasCleaner ? 1.5 : 0) +
      (this.hasCopy ? 1.5 : 0)
    );
  }

  @HostBinding(`class.raised`)
  public get labelRaised(): boolean {
    return this.focused || this.hasValue || this.placeholder !== '';
  }

  recalculateLabelWidth$ = new Subject<void>();
  protected readonly recalculateLabelWidthSub = this.recalculateLabelWidth$
    .pipe(throttleTime(0), takeUntilDestroyed())
    .subscribe(() => {
      setTimeout(() => this.calcLabelWidth(), 200);
    });

  get hasCleaner(): boolean {
    return this.hasValue && this.cleaner;
  }

  private get hasValue(): boolean {
    return !(
      this.value === null ||
      this.value === undefined ||
      this.value.toString().trim() === ''
    );
  }

  get hasCopy(): boolean {
    return this.hasValue && this.copier;
  }

  constructor(
    public elementRef: ElementRef,
    private clipboardService: ClipboardService,
    private toastr: ToastrService
  ) {}

  ngAfterContentChecked() {
    this.recalculateLabelWidth$.next();
  }

  public clear(): void {
    this.updateValue('');
    this.onClear.emit();
  }

  public onModelChange(value: string): void {
    this.updateValue(value);
  }

  private updateValue(value: string): void {
    this.value = value;
    this.valueChange.emit(value);
  }

  public copy(): void {
    this.toastr.info(`Значение ${this.value} скопировано в буфер обмена`);
    this.clipboardService.copy(this.value);
  }

  // Ограничиваем максимальную ширину лейбла, делая ее не больше чем ширина всего контрола.
  private calcLabelWidth() {
    const labelMaxWidth = Math.max(
      INPUT_LABEL_MIN_WIDTH,
      (this.elementRef?.nativeElement.offsetWidth || INPUT_LABEL_RIGHT_OFFSET) -
        INPUT_LABEL_RIGHT_OFFSET
    );
    this.inputLabel?.nativeElement.style.setProperty(
      'max-width',
      `${labelMaxWidth}px`
    );
  }
}
