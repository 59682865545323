import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ServiceHelpInfo,
  TopsellerServices,
  TS_SERVICES_INFO,
} from '@topseller/core';

@Component({
  selector: 'ts-service-initial-modal',
  templateUrl: './service-initial-modal.component.html',
  styleUrls: ['./service-initial-modal.component.scss'],
})
export class ServiceInitialModalComponent {
  tsServices = TopsellerServices;

  constructor(
    public dialogRef: MatDialogRef<ServiceInitialModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { serviceId: TopsellerServices; title: string },
    @Inject(TS_SERVICES_INFO)
    public servicesHelpInfo: Record<TopsellerServices, ServiceHelpInfo>
  ) {}

  save() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }
}
