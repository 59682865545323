/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 23.0220.1356
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { BarcodeType } from '../model/barcode';


import { Configuration } from '../configuration';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';

import { Paginated } from '../model/paginated';
import { Record } from "@topseller/core";


@Injectable()
export class BarcodeTypeService {

    protected basePath = 'http://denis.dev.topseller.ru';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Удалить тип штрих-кода
     *
     * @param barcodeTypeId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAppBarcodetypeDelete(barcodeTypeId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAppBarcodetypeDelete(barcodeTypeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAppBarcodetypeDelete(barcodeTypeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAppBarcodetypeDelete(barcodeTypeId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (barcodeTypeId === null || barcodeTypeId === undefined) {
            throw new Error('Required parameter barcodeTypeId was null or undefined when calling deleteAppBarcodetypeDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete', `${this.basePath}/api/v1/barcode-types/${encodeURIComponent(String(barcodeTypeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Список типов штрих-кодов
     *
     * @param limit Кол-во, выводимое на страницу
     * @param offset Смещение указателя
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppBarcodetypeIndex(limit?: number, offset?: number, observe?: 'body', reportProgress?: boolean): Observable<Paginated<BarcodeType>>;
    public getAppBarcodetypeIndex(limit?: number, offset?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Paginated<BarcodeType>>>;
    public getAppBarcodetypeIndex(limit?: number, offset?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Paginated<BarcodeType>>>;
    public getAppBarcodetypeIndex(limit?: number, offset?: number, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Paginated<BarcodeType>>>('get', `${this.basePath}/api/v1/barcode-types`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Тип штрих-кода
     *
     * @param barcodeTypeId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppBarcodetypeView(barcodeTypeId: string, observe?: 'body', reportProgress?: boolean): Observable<BarcodeType>;
    public getAppBarcodetypeView(barcodeTypeId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BarcodeType>>;
    public getAppBarcodetypeView(barcodeTypeId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BarcodeType>>;
    public getAppBarcodetypeView(barcodeTypeId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (barcodeTypeId === null || barcodeTypeId === undefined) {
            throw new Error('Required parameter barcodeTypeId was null or undefined when calling getAppBarcodetypeView.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<BarcodeType>('get', `${this.basePath}/api/v1/barcode-types/${encodeURIComponent(String(barcodeTypeId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Изменить тип штрих-кода
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchAppBarcodetypeUpdate(body?: BarcodeType, observe?: 'body', reportProgress?: boolean): Observable<BarcodeType>;
    public patchAppBarcodetypeUpdate(body?: BarcodeType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BarcodeType>>;
    public patchAppBarcodetypeUpdate(body?: BarcodeType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BarcodeType>>;
    public patchAppBarcodetypeUpdate(body?: BarcodeType, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<BarcodeType>>('patch', `${this.basePath}/api/v1/barcode-types`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAppBarcodetypeBatchdelete(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postAppBarcodetypeBatchdelete(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postAppBarcodetypeBatchdelete(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postAppBarcodetypeBatchdelete(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post', `${this.basePath}/api/v1/barcode-types/batch-delete`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Создать тип штрих-кода
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAppBarcodetypeCreate(body?: BarcodeType, observe?: 'body', reportProgress?: boolean): Observable<BarcodeType>;
    public postAppBarcodetypeCreate(body?: BarcodeType, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<BarcodeType>>;
    public postAppBarcodetypeCreate(body?: BarcodeType, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<BarcodeType>>;
    public postAppBarcodetypeCreate(body?: BarcodeType, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<BarcodeType>>('post', `${this.basePath}/api/v1/barcode-types`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
