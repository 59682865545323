import { InjectionToken } from '@angular/core';
import { ServiceHelpInfo, TopsellerServices } from './servicesHelpInfo';

export const BASE_PATH = new InjectionToken<string>('basePath');

export enum AppsEnum {
  HUB = 'hub',
}

export const APP_NAME = new InjectionToken<AppsEnum>('App name');

export const TS_SERVICES_INFO = new InjectionToken<Record<TopsellerServices, ServiceHelpInfo>>('tsServicesInfo')
