export interface ServiceHelpInfo {
  helpUrl: string;
  videoUrl: string; // ссылка на видео из плашки помощи
  helpVideos?: string[]; // доп. видосы для отображения в помощи
  helpUrlTitle?: string; // оверрайд заголовка ссылки на помощь
  modalVideoUrl: string; // ссылка на видео из модалки сервиса
  description?: string;
}

export enum TopsellerServices {
  HUB = 'hub',
  CONNECT = 'connect',
  PIM = 'pim',
  ANALYTICS = 'analytics',
  REPRICER = 'repricer',
  AITOOLS = 'aitools',
  WBADS = 'wbads',
  DROPSHIP = 'dropship',
  JOURNAL = 'journal',
  PULSE = 'pulse',
  default = 'default',
}

export const servicesHelpInfo: Record<TopsellerServices, ServiceHelpInfo> = {
  [TopsellerServices.HUB]: {
    helpUrl: ' https://help.topseller.ru/instruction/self-tuning',
    videoUrl: 'https://vkvideo.ru/playlist/-215649227_10',
    helpVideos: [
      'https://vk.com/video_ext.php?oid=-215649227&id=456239098&hd=2&autoplay=0',
      'https://vk.com/video_ext.php?oid=-215649227&id=456239272&hd=2&autoplay=0',
    ],
    helpUrlTitle: 'Настрою сам',
    modalVideoUrl:
      'https://vk.com/video_ext.php?oid=-215649227&id=456239098&hd=2&autoplay=0',
    description:
      '<b>Hub</b> – новый сервис товарного учета от Topseller, разработанный специально для селлеров. Наша система учета поможет управлять бизнесом с легкостью, удобством и точностью.',
  },
  [TopsellerServices.CONNECT]: {
    helpUrl: 'https://help.topseller.ru/services/connect',
    videoUrl: 'https://vk.com/video-215649227_456239272',
    modalVideoUrl:
      'https://vk.com/video_ext.php?oid=-215649227&id=456239272&hd=2&autoplay=0',
    description:
      '<b>Connect</b> – это самая функциональная интеграция, оформленная в виде приложения для Ozon, Wildberries, Яндекс.Маркет, AliExpress, СберМегаМаркет и Леруа Мерлен. Интеграция позволяет обмениваться заказами, статусами и отгрузками, переносить товары и синхронизировать остатки. Поддерживается работа по FBS/DBS/FBO/FBW/FBY/FBA, печать этикеток, уведомления.',
  },
  [TopsellerServices.PIM]: {
    helpUrl: 'https://help.topseller.ru/services/pim',
    videoUrl: 'https://vk.com/video-215649227_456239245',
    modalVideoUrl:
      'https://vk.com/video_ext.php?oid=-215649227&id=456239245&hd=2&autoplay=0',
    description:
      '<b>PIM</b> – сервис по работе с карточками товаров на маркетплейсах. PIM значительно упрощает выгрузку карточек товаров в маркетплейсы OZON, Wildberries и Яндекс Маркет. Основное отличие сервиса – это автоматическое связывание категорий и атрибутов между маркетплейсами. Это позволяет экономить время и снижает вероятность ошибок при выгрузке.',
  },
  [TopsellerServices.ANALYTICS]: {
    helpUrl: 'https://help.topseller.ru',
    videoUrl: 'youtube.com/channel/UCWGC8ydgkRTJv86Y6xS3-sQ',
    modalVideoUrl: 'youtube.com/channel/UCWGC8ydgkRTJv86Y6xS3-sQ',
  },
  [TopsellerServices.REPRICER]: {
    helpUrl: 'https://help.topseller.ru/services/repricer',
    videoUrl: 'https://vk.com/video-215649227_456239237',
    modalVideoUrl:
      'https://vk.com/video_ext.php?oid=-215649227&id=456239237&hd=2&autoplay=0',
    description:
      '<b>Repricer</b> – это сервис для автоматического управления ценами на Ozon и Wildberries. Сервис автоматически отслеживает ваших конкурентов и их ценовую политику, позволяет обновлять цены в режиме реального времени и устанавливать самую выгодную цену на маркетплейсе.',
  },
  [TopsellerServices.AITOOLS]: {
    helpUrl: 'https://help.topseller.ru/services/aitools',
    videoUrl: 'https://vk.com/video-215649227_456239246',
    modalVideoUrl:
      'https://vk.com/video_ext.php?oid=-215649227&id=456239246&hd=2&autoplay=0',
    description:
      '<b>AiTools</b> – сервис автоматизации отзывов на Wildberries powered by ChatGPT, обученный под маркетплейсы. AiTools позволяет настроить автоматические ответы на различные типы отзывов, что позволяет поддерживать положительный имидж вашего бренда на маркетплейсе.',
  },
  [TopsellerServices.WBADS]: {
    helpUrl: 'https://help.topseller.ru/services/ads',
    videoUrl: 'https://vk.com/video-215649227_456239027',
    modalVideoUrl:
      'https://vk.com/video_ext.php?oid=-215649227&id=456239027&hd=2&autoplay=0',
    description:
      '<b>Ads</b> – это автоматизированный сервис для работы с рекламой и рекламными кампаниями на Wildberries.',
  },
  [TopsellerServices.DROPSHIP]: {
    helpUrl: 'https://help.topseller.ru',
    videoUrl: 'youtube.com/channel/UCWGC8ydgkRTJv86Y6xS3-sQ',
    modalVideoUrl: 'youtube.com/channel/UCWGC8ydgkRTJv86Y6xS3-sQ',
    description:
      '<span>Для тех, кто хочет снизить издержки на хранение товара и аренду склада мы разработали сервис <b>Dropship</b>. Теперь вы можете настроить интеграцию с поставщиками в простом и понятном интерфейсе; автоматически получать остатки, цены и товары от поставщиков в учетную систему.</span>',
  },
  [TopsellerServices.JOURNAL]: {
    helpUrl: 'https://help.topseller.ru',
    videoUrl: 'youtube.com/channel/UCWGC8ydgkRTJv86Y6xS3-sQ',
    modalVideoUrl: 'youtube.com/channel/UCWGC8ydgkRTJv86Y6xS3-sQ',
  },
  [TopsellerServices.PULSE]: {
    helpUrl: 'https://help.topseller.ru',
    videoUrl: 'youtube.com/channel/UCWGC8ydgkRTJv86Y6xS3-sQ',
    modalVideoUrl: 'youtube.com/channel/UCWGC8ydgkRTJv86Y6xS3-sQ',
  },
  [TopsellerServices.default]: {
    helpUrl: 'https://help.topseller.ru',
    videoUrl: 'youtube.com/channel/UCWGC8ydgkRTJv86Y6xS3-sQ',
    modalVideoUrl: 'youtube.com/channel/UCWGC8ydgkRTJv86Y6xS3-sQ',
  },
};
