import { Component } from '@angular/core';
import { NavLink } from '@topseller/common';
import { Store } from '@ngrx/store';
import { setCurrentProductInfo } from '@topseller/core';

@Component({
  selector: 'ts-analytics',
  templateUrl: 'analytics.component.html',
  styleUrls: ['analytics.component.scss']
})

export class AnalyticsComponent {

  public links: NavLink[] = [
    {
      title: 'Аналитика',
      path: '/analytics/main',
      iconName: 'shop-Home',
      sub: [
        { title: 'Категории', path: '/analytics/main/categories' },
        { title: 'Товары', path: '/analytics/main/products' },
        { title: 'Селлеры', path: '/analytics/main/sellers' },
        { title: 'Бренды', path: '/analytics/main/brands' },
      ],
    },
    // {
    //   title: 'СЕО',
    //   path: '/analytics/seo',
    //   iconName:'document-note',
    // },
    {
      title: 'Избранное',
      path: '/analytics/favorites',
      iconName: 'shop-Home',
      sub: [
        { title: 'Категории', path: '/analytics/favorites/categories' },
        { title: 'Товары', path: '/analytics/favorites/products' },
        { title: 'Селлеры', path: '/analytics/favorites/sellers' },
        { title: 'Бренды', path: '/analytics/favorites/brands' },
      ],
    },
    {
      title: 'Сравнение',
      path: '/analytics/comparison',
      iconName: 'shop-Home',
      sub: [
        { title: 'Категории', path: '/analytics/comparison/categories' },
        { title: 'Товары', path: '/analytics/comparison/products' },
        { title: 'Селлеры', path: '/analytics/comparison/sellers' },
        { title: 'Бренды', path: '/analytics/comparison/brands' },
      ],
    },
  ];

  constructor(private store: Store) {
    this.store.dispatch(
      setCurrentProductInfo({currentProductInfo: {currentProduct:'analytics'}})
    );
  }
}
