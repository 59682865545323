import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { UserInfoComponent } from './user-info/user-info.component';
import { NavbarComponent } from './navbar';
import { MainNavComponent } from './main-nav/main-nav.component';

import {
  TsDropdownModule,
  TsChipModule,
  ActiveZoneModule,
  TsScrollModule,
  BaseInputModule,
  TsCustomContentComponent,
  TsHintModule,
  RoleAccessWrapperComponent,
  DotLoaderComponent,
} from '@topseller/ui';

import { KeyInfoComponent } from './key-info/key-info.component';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { NavbarDirective } from './navbar/navbar.directive';
import { MultiSelectModule } from './multi-select';
import { SafeUrlPipe } from './pipe';

const COMPONENTS = [
  UserInfoComponent,
  NavbarComponent,
  KeyInfoComponent,
  CheckboxComponent,
  NavbarDirective,
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TsDropdownModule,
    TsChipModule,
    ActiveZoneModule,
    FormsModule,
    TsScrollModule,
    TsHintModule,
    RoleAccessWrapperComponent,
    BaseInputModule,
    TsCustomContentComponent,
    MultiSelectModule,
    DotLoaderComponent,
    SafeUrlPipe,
  ],
  declarations: [MainNavComponent, ...COMPONENTS],
  exports: [...COMPONENTS],
})
export class TsCommonModule {}
