  <ts-items-table [tableHeaders]="tableHeaders" [isSelectable]="false"
                  class="files-table"
                  tsStretchToBottom [offset]="16"
                  [ngClass]="{'table-scroll-loading':isLoading$|async}">
    <ts-file-list-item *tsTableCellTemplate="'previewType', let file" [file]="file"></ts-file-list-item>

    <ts-modified-by-cell *tsTableCellTemplate="'modifiedBy' let file"
                         [changeableRecord]="toChangeableRecord(file)"></ts-modified-by-cell>

    <ng-container *tsTableCellTemplate="'size' let file">
      <span>{{ file.size|bytesToMegabytes }}</span>
    </ng-container>

    <div *tsTableCellTemplate="'action', let file">
      <button type="button" class="ts-btn error ts-btn-bezeled ts-btn-md ts-btn-icon" (click)="deleteFile(file!)">
        <i class="ts-icon ts-icon-actions-Trash"></i>
      </button>
    </div>

  </ts-items-table>
