<div class="form-controls-card__content">
  <div class="edit-comment" *ngIf="editBlockShow$ | async">
    <div
      class="ts-icon ts-icon-actions-Edit text-2lg ts-text-icon-info-primary-color"
    ></div>
    <div class="flex-grow-1 overflow-hidden">
      <div class="ts-text-info-color ts-caption-strong">
        Редактирование комментария
      </div>
      <div class="text-truncate">{{ commentForEdit?.text }}</div>
    </div>
    <div
      class="cursor-pointer ts-icon ts-icon-actions-Close text-2lg ts-text-icon-neutral-color"
      (click)="cancelEdit()"
    >
      ️
    </div>
  </div>
  <ts-input-comment
    [formControl]="commentsFormControl"
    (send)="save()"
    [uploadFilesFn]="uploadFilesApi"
    [attachedFiles]="attachedFiles"
    (filesUpdated)="updateAttachedFiles($event)"
    [readonly]="isSaving$ | async"
  ></ts-input-comment>
  <div class="mt-4 ts-subtitle mb-3">Все комментарии ({{ totalCount }})</div>

  <div *ngIf="employee$ | async as employee"
    class="d-flex flex-column gap-3 comments-container"
    tsStretchToBottom [offset]="16"
  >
    <ts-scroll>
      <ts-data-scroll (scrolled)="onScroll()" [options]="dataScrollOptions">
        <ng-container *ngIf="items$|async">
          <ts-scroll>
            <div class="d-flex flex-column gap-3" *ngIf="items?.length; else empty">
              <ts-comment
                @commentAnimation
                *ngFor="let comment of items; trackBy: identify"
                [comment]="comment"
                [isEditing]="commentForEdit?.id === comment.id"
                [employee]="employee"
                (edit)="onEdit($event)"
                (delete)="onCommentDelete($event)"
              ></ts-comment>
            </div>
          </ts-scroll>
        </ng-container>
        <ts-dot-loader *ngIf="isLoading$|async"></ts-dot-loader>

      </ts-data-scroll>

    </ts-scroll>
    <ng-template #empty>
      <div class="comments-list__empty">
        <img alt="no comments" src="/assets/img/hub/comment/comments-empty.svg" />
      </div>
    </ng-template>
  </div>
</div>
