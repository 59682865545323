import {Inject, Injectable, Optional} from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import {BASE_PATH} from '../variables';
import {Observable} from "rxjs";
import {Paginated} from "@topseller/core";
import {IndicatorsGroup} from "../model/indicator-value";

@Injectable()
export class IndicatorsApiService {
  protected basePath = 'http://denis.dev.topseller.ru';

  constructor(protected httpClient: HttpClient,
              @Optional() @Inject(BASE_PATH) basePath: string,) {
    if (basePath) {
      this.basePath = basePath;
    }
  }

  getIndicators(dateFrom: string | null = null, dateTo: string | null = null): Observable<Paginated<IndicatorsGroup>> {
    let params = new HttpParams();
    if (dateFrom)
      params = params.set('dateFrom', dateFrom);
    if (dateTo)
      params = params.set('dateTo', dateTo);
    return this.httpClient.get<Paginated<IndicatorsGroup>>(`${this.basePath}/api/v1/indicators`, {params});
  }
}
