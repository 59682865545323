import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectComponent } from '.';

import {
  TsChipModule,
  ActiveZoneModule,
  BaseInputModule,
  TsCustomContentComponent,
  TsDropdownModule,
} from '@topseller/ui';

@NgModule({
  declarations: [MultiSelectComponent],
  imports: [
    CommonModule,
    ActiveZoneModule,
    BaseInputModule,
    TsCustomContentComponent,
    TsChipModule,
    TsDropdownModule,
  ],
  exports: [MultiSelectComponent],
})
export class MultiSelectModule {}
