import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TsAbstractControl } from '@topseller/cdk/abstract';
import { FormsModule, NgControl } from '@angular/forms';
import {
  getNativeFocused,
  TsTextareaAutoresizeDirective,
  TsInputTextModule,
} from '@topseller/ui';

import { BehaviorSubject, Observable, Subject, takeUntil } from 'rxjs';
import { File as AttachedFile, PaginatedFile } from '@topseller/core';
import { AttachedFileComponent } from '../attached-file';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ts-input-comment',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    AttachedFileComponent,
    TsInputTextModule,
  ],
  templateUrl: './input-comment.component.html',
  styleUrls: ['./input-comment.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InputCommentComponent
  extends TsAbstractControl<string | null>
  implements OnDestroy
{
  @ViewChild('textarea', { static: true, read: ElementRef })
  public inputElement?: ElementRef;

  @Input() public placeholder = 'Написать сообщение...';

  @Input() public readonly = false;

  @Input() uploadFilesFn!: (files: File[]) => Observable<AttachedFile[]>;

  @Input() attachedFiles: AttachedFile[] = [];

  @Output()
  public readonly focusChanged = new EventEmitter<boolean>();

  @Output() send = new EventEmitter();
  @Output() filesUpdated = new EventEmitter();

  loading$ = new BehaviorSubject(false);
  destroy$ = new Subject<void>();

  constructor(
    @Self() @Optional() ngControl: NgControl,
    changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    private toastrService: ToastrService
  ) {
    super(ngControl, changeDetectorRef);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  // Эти события слушать обязательно для корректного отслеживания focused
  @HostListener('focusin', ['true'])
  @HostListener('focusout', ['false'])
  public onFocused(focused: boolean) {
    this.focusChanged.emit(focused);
  }

  @HostBinding(`class.focused`)
  public get focused(): boolean {
    const node = this.elementRef.nativeElement;
    const documentRef = node.ownerDocument;

    const element = getNativeFocused(documentRef);
    return !!element && node.contains(element);
  }

  protected getFallbackValue(): string | null {
    return null;
  }

  public get hasValue(): boolean {
    return !(
      (this.value === null ||
        this.value === undefined ||
        this.value.toString().trim() === '') &&
      this.attachedFiles.length == 0
    );
  }

  public onModelChange(value: string): void {
    this.updateValue(value);
  }

  deleteFile(index: number) {
    this.attachedFiles = [
      ...this.attachedFiles.slice(0, index),
      ...this.attachedFiles.slice(index + 1),
    ];
    this.filesUpdated.emit(this.attachedFiles);
  }

  addFiles(event: Event) {
    const inputElement = event.target as HTMLInputElement;

    if (inputElement.files) {
      this.loading$.next(true);
      const files: File[] = Array.from(inputElement.files);

      this.uploadFilesFn(files)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (res: PaginatedFile[]) => {
            res.forEach((el: PaginatedFile) => {
              this.attachedFiles = [el].concat(this.attachedFiles);
            });
            this.filesUpdated.emit(this.attachedFiles);
            this.toastrService.success('Файлы успешно загружены');
          },
          error: (err: any) => {
            this.toastrService.error(
              err?.errors?.length && err.errors[0].message
                ? err.errors[0].message
                : err?.message || 'Что-то пошло не так'
            );
            this.loading$.next(false);
            this.changeDetectorRef.markForCheck();
          },
        });

      inputElement.value = '';
    }
  }
}
