import { Country } from './../model/country';
import { Paginated } from './../model/paginated';
/**
 * TOPSELLER App
 * Topseller backend API
 *
 * OpenAPI spec version: 23.0220.1356
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { CustomHttpUrlEncodingCodec } from '../encoder';

import { Observable } from 'rxjs';

import { Configuration } from '../configuration';
import { BASE_PATH, COLLECTION_FORMATS } from '../variables';


@Injectable()
export class CountryService {

    protected basePath = 'http://denis.dev.topseller.ru';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional() @Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * Удалить страну
     * 
     * @param countryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteAppCountryDelete(countryId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public deleteAppCountryDelete(countryId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public deleteAppCountryDelete(countryId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public deleteAppCountryDelete(countryId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling deleteAppCountryDelete.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete', `${this.basePath}/api/v1/countries/${encodeURIComponent(String(countryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Список стран
     * 
     * @param limit Кол-во, выводимое на страницу
     * @param offset Смещение указателя
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppCountryIndex(limit?: number, offset?: number, search?: string, observe?: 'body', reportProgress?: boolean): Observable<Paginated<Country>>;
    public getAppCountryIndex(limit?: number, offset?: number, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Paginated<Country>>>;
    public getAppCountryIndex(limit?: number, offset?: number, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Paginated<Country>>>;
    public getAppCountryIndex(limit?: number, offset?: number, search?: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {



        let queryParameters = new HttpParams({ encoder: new CustomHttpUrlEncodingCodec() });
        if (search !== undefined && search !== null) {
            queryParameters = queryParameters.set('search', <any>search);
        }
        if (limit !== undefined && limit !== null) {
            queryParameters = queryParameters.set('limit', <any>limit);
        }
        if (offset !== undefined && offset !== null) {
            queryParameters = queryParameters.set('offset', <any>offset);
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<Paginated<Country>>>('get', `${this.basePath}/api/v1/countries`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Получить страну
     * 
     * @param countryId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getAppCountryView(countryId: string, observe?: 'body', reportProgress?: boolean): Observable<Country>;
    public getAppCountryView(countryId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Country>>;
    public getAppCountryView(countryId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Country>>;
    public getAppCountryView(countryId: string, observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        if (countryId === null || countryId === undefined) {
            throw new Error('Required parameter countryId was null or undefined when calling getAppCountryView.');
        }

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Country>('get', `${this.basePath}/api/v1/countries/${encodeURIComponent(String(countryId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Отредактировать страну
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public patchAppCountryUpdate(body?: Country, observe?: 'body', reportProgress?: boolean): Observable<Country>;
    public patchAppCountryUpdate(body?: Country, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Country>>;
    public patchAppCountryUpdate(body?: Country, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Country>>;
    public patchAppCountryUpdate(body?: Country, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Country>('patch', `${this.basePath}/api/v1/countries`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAppCountryBatchdelete(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public postAppCountryBatchdelete(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public postAppCountryBatchdelete(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public postAppCountryBatchdelete(observe: any = 'body', reportProgress: boolean = false): Observable<any> {

        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post', `${this.basePath}/api/v1/countries/batch-delete`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Добавить страну
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public postAppCountryCreate(body?: Country, observe?: 'body', reportProgress?: boolean): Observable<Country>;
    public postAppCountryCreate(body?: Country, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Country>>;
    public postAppCountryCreate(body?: Country, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Country>>;
    public postAppCountryCreate(body?: Country, observe: any = 'body', reportProgress: boolean = false): Observable<any> {


        let headers = this.defaultHeaders;

        // authentication (bearerAuth) required
        if (this.configuration.accessToken) {
            const accessToken = typeof this.configuration.accessToken === 'function'
                ? this.configuration.accessToken()
                : this.configuration.accessToken;
            headers = headers.set('Authorization', 'Bearer ' + accessToken);
        }
        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'application/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Country>('post', `${this.basePath}/api/v1/countries`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
