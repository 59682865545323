import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { loadProductGroups } from '../pages/products-services/store';

@Injectable({
  providedIn: 'root',
})
export class ProductGroupsResolver {
  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    this.store.dispatch(loadProductGroups());
  }
}
