import {Inject, Injectable, Optional} from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { BASE_PATH } from '../variables';
import {Observable} from "rxjs";
import {DashboardReportDetail, ReportOrderSales} from "../model/reports/dashboard-report-base";

@Injectable()
export class ReportOrderSaleApiService {
    protected basePath = 'http://denis.dev.topseller.ru';

    constructor(protected httpClient: HttpClient,
                @Optional() @Inject(BASE_PATH) basePath: string,) {
        if (basePath) {
            this.basePath = basePath;
        }
    }

    createReport(filter: string): Observable<ReportOrderSales> {
        return this.httpClient.get<ReportOrderSales>(`${this.basePath}/api/v1/report/order/sales?${filter}`);
    }

    getReportDetail(reportId: string): Observable<HttpResponse<DashboardReportDetail>> {
        return this.httpClient.get<DashboardReportDetail>(`${this.basePath}/api/v1/report/order/sales/${encodeURIComponent(
            String(reportId)
        )}/detail`, {observe: 'response'});
    }
}
